
import { Vue, Component } from 'vue-property-decorator'
import { ClinicAPI } from '@/api'
import { Message } from 'view-design'
import moment from 'moment'
@Component({
  components: {
  }
})
export default class DoctorDetail extends Vue {
  protected errorImg: string = require('@/assets/clinic/defaultAvatar.png')
  private doctorId = ''
  private doctorInfo: any = {}
  private doctorScheduling: any[] = []
  private columns: any[] = [{
    title: '门诊时间',
    slot: 'outpatientTime',
    width: 200,
    align: 'center'
  }, {
    title: '选择时间段',
    slot: 'timeList',
    minWidth: 500,
    align: 'left'
  },
  {
    title: '可否预约',
    slot: 'action',
    width: 300,
    align: 'center'
  }]
  private curCheckDate = ''
  private mounted () {
    this.doctorId = this.$route.query.id + ''
    this.getClinicDoctorDetail()
    this.getDoctorScheduling()
  }
  private goPay (row: any) {
    const timeList: any = []
    row.timeList.map((item: any) => {
      if (item.checked) {
        timeList.push({ ...item })
      }
    })
    const clinicJson = { ...row, timeList }
    if (clinicJson.timeList.length === 0) {
      (Message as any).error('请选择时间段')
    } else {
      this.$store.dispatch('setClinicJson', clinicJson)
      this.$router.push('/hxqClinic/pay?id=' + this.doctorId + '&price=' + this.doctorInfo.offlineMoney)
    }
  }
  private getClinicDoctorDetail () {
    ClinicAPI.getClinicDoctorDetail(this.doctorId).then((res) => {
      this.doctorInfo = res.result
    })
  }
  private selectTime (item: any, date: any, index: number) {
    if (!item.checked) {
      if (this.curCheckDate === '' || this.curCheckDate === date.index) {// 如果未选择或者为同一天
        // 判断是否为连续
        let isPre = false
        let isNext = false
        this.doctorScheduling[+this.curCheckDate].timeList.forEach((p: any) => {
          if (p.checked) {
            if (p.code === item.code + 1) isPre = true
            if (p.code === item.code - 1) isNext = true
          }
        })
        if (this.curCheckDate === '') isPre = true

        if (!isPre && !isNext) {
          this.$Message.info('只能选择连续时间段')
        } else {
          this.curCheckDate = date.index
          // item.checked = true
          this.doctorScheduling[+this.curCheckDate].timeList[index].checked = true
        }
      } else { // 选择不同的天
        this.$Message.info('只能选择同一天的时间段')
      }
    } else {
      let firstIndex = -1
      this.doctorScheduling[+this.curCheckDate].timeList.forEach((p: any, i: number) => {
        if (p.checked) {
          if (firstIndex === -1) firstIndex = i
          // 如果不是第一个选项，把选中项以及后面的都清除掉
          if (index !== firstIndex && i > index) {
            p.checked = false
          }
        }
      })
      this.doctorScheduling[+this.curCheckDate].timeList[index].checked = false
      if (this.doctorScheduling.filter((d: any) => {
        return d.timeList.filter((p: any) => {
          return p.checked
        }).length > 0
      }).length === 0) {
        this.curCheckDate = ''
      }
    }
  }
  private getDoctorScheduling () {
    ClinicAPI.getDoctorScheduling(this.doctorId).then((res) => {
      this.doctorScheduling = res.result.map((item: any, index: number) => {
        const timeList = item.timeList.map((i: any) => {
          return {
            ...i,
            checked: false
          }
        })
        return {
          ...item,
          index,
          timeList
        }
      })
    })
  }
}
